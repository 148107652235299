/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  /** @format uuid */
  userId?: string;
  user?: User;
  name?: string | null;
  tenant?: Tenant;
  /** @format uuid */
  tenantId?: string | null;
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string | null;
  /** @format uuid */
  createdByAccountId?: string | null;
  /** @format uuid */
  updatedByAccountId?: string | null;
  createdByEmail?: string | null;
  updatedByEmail?: string | null;
}

export interface AccountDto {
  name?: string | null;
  /** @format uuid */
  id?: string;
}

export interface ActionDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
  noun?: string | null;
  displayName?: string | null;
  description?: string | null;
  helpText?: string | null;
  generatedSampleData?: string | null;
  manualSampleData?: string | null;
  /** @format uuid */
  platformId?: string | null;
  platform?: PlatformDto;
  isAutoManaged?: boolean;
  isSimpleStep?: boolean;
  isArrayResponse?: boolean;
  summary?: string | null;
  tag?: string | null;
  codeName?: string | null;
  operationJson?: string | null;
  inputs?: InputPropertyDto[] | null;
  outputs?: OutputPropertyDto[] | null;
  httpRequestInfo?: HttpRequestInfoDto;
  stepTypeName?: string | null;
  isTested?: boolean;
  isTestPassed?: boolean;
  hideFromAppDevelopers?: boolean;
}

export interface ActionsFromOpenApiDto {
  openApiUrl?: string | null;
  openApiText?: string | null;
  /** @format uuid */
  platformId?: string;
}

export interface ApiKeyAuthDto {
  apiKey?: string | null;
}

export interface ApiKeyResultDto {
  apiKey?: string | null;
}

export interface AppDto {
  name?: string | null;
  codeName?: string | null;
  /** @format uuid */
  tenantId?: string;
  environment?: EnvironmentType;
  status?: PublishStatus;
  /** @format int32 */
  version?: number;
  isReadOnly?: boolean;
  description?: string | null;
  featuresAndBenefits?: string | null;
  installationSteps?: string | null;
  beforeYouStart?: string | null;
  otherResources?: string | null;
  needHelp?: string | null;
  developerName?: string | null;
  helpEmail?: string | null;
  salesEmail?: string | null;
  logoUrl?: string | null;
  isInstalled?: boolean;
  /** @format uuid */
  id?: string;
}

export enum AuthLevel {
  Tenant = "Tenant",
  User = "User",
}

export enum AuthType {
  None = "None",
  OAuth = "OAuth",
  ApiKey = "ApiKey",
  Basic = "Basic",
}

export interface BasicAuthDto {
  username?: string | null;
  password?: string | null;
}

export interface BasicAuthResultDto {
  username?: string | null;
  password?: string | null;
}

export interface CodeEvaluationDto {
  code?: string | null;
  data?: any;
}

export interface CodeEvaluationResponseDto {
  result?: string | null;
  success?: boolean;
  errorMessage?: string | null;
}

export interface Definition {
  id?: string | null;
  /** @format int32 */
  version?: number;
  description?: string | null;
  defaultErrorBehavior?: WorkflowErrorHandling;
  /** @format date-span */
  defaultErrorRetryInterval?: string | null;
  steps?: Step[] | null;
}

export enum EnvironmentType {
  Development = "Development",
  Staging = "Staging",
  Production = "Production",
}

export enum HttpMethodType {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
  HEAD = "HEAD",
  TRACE = "TRACE",
}

export interface HttpRequestInfoDto {
  /** @format uuid */
  id?: string | null;
  url?: string | null;
  method?: HttpMethodType;
  contentType?: string | null;
  parameters?: Record<string, string>;
  headers?: Record<string, string>;
  body?: Record<string, string>;
}

export interface InputPropertyDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  actionId?: string;
  schemaType?: SchemaType;
  displayName?: string | null;
  description?: string | null;
  propertyName?: string | null;
  propertyType?: JsonPropertyType;
  required?: boolean;
  enum?: string[] | null;
  /** @format double */
  maximum?: number | null;
  /** @format double */
  minimum?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  /** @format int32 */
  minLength?: number | null;
  format?: string | null;
  nullable?: boolean;
  /** @format int32 */
  maxItems?: number | null;
  /** @format int32 */
  maxProperties?: number | null;
  pattern?: string | null;
  deprecated?: boolean;
  defaultValue?: string | null;
  /** @format int32 */
  order?: number;
}

export enum JsonPropertyType {
  String = "String",
  Number = "Number",
  Integer = "Integer",
  Boolean = "Boolean",
  Object = "Object",
  Array = "Array",
}

export interface MeDto {
  user?: UserDto;
  account?: AccountDto;
  tenant?: TenantDto;
  /** @format int32 */
  appCount?: number;
  isSuperAdminTenant?: boolean;
}

export interface OAuthResultDto {
  accessToken?: string | null;
  refreshToken?: string | null;
  /** @format date-time */
  expiresAt?: string;
  /** @format int32 */
  expiresIn?: number;
}

export interface OAuthSettingDto {
  authCodeExchangeUrl?: string | null;
  authCodeExchangeMethod?: string | null;
  authCodeExchangeContentType?: string | null;
  accessTokenRefreshUrl?: string | null;
  /** @format int32 */
  authCodeExpiresInSecondsOverride?: number;
  loginUrlTemplate?: string | null;
  tokenType?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  scopes?: string | null;
  callbackUrl?: string | null;
  loginUrl?: string | null;
  /** @format uuid */
  id?: string;
}

export interface OrderBy {
  key?: string | null;
  order?: string | null;
}

export interface OrderedRecordDto {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  order?: number;
}

export interface OutputPropertyDto {
  propertySelector?: string | null;
  /** @format uuid */
  id?: string;
  /** @format uuid */
  actionId?: string;
  schemaType?: SchemaType;
  displayName?: string | null;
  description?: string | null;
  propertyName?: string | null;
  propertyType?: JsonPropertyType;
  required?: boolean;
  enum?: string[] | null;
  /** @format double */
  maximum?: number | null;
  /** @format double */
  minimum?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  /** @format int32 */
  minLength?: number | null;
  format?: string | null;
  nullable?: boolean;
  /** @format int32 */
  maxItems?: number | null;
  /** @format int32 */
  maxProperties?: number | null;
  pattern?: string | null;
  deprecated?: boolean;
  defaultValue?: string | null;
  /** @format int32 */
  order?: number;
}

export interface PlatformAuthDto {
  label?: string | null;
  specificPlatformProductName?: string | null;
  /** @format uuid */
  specificPlatformId?: string;
  platformGlobalId?: string | null;
  basicAuthResult?: BasicAuthResultDto;
  /** @format uuid */
  basicAuthResultId?: string | null;
  apiKeyResult?: ApiKeyResultDto;
  /** @format uuid */
  apiKeyResultId?: string | null;
  oAuthResult?: OAuthResultDto;
  /** @format uuid */
  oAuthResultId?: string | null;
  platformAccountId?: string | null;
  authType?: AuthType;
  apiResponse?: string | null;
  /** @format uuid */
  id?: string;
}

export interface PlatformDto {
  logoUrl?: string | null;
  /** @maxLength 64 */
  productName?: string | null;
  /** @maxLength 64 */
  vendorName?: string | null;
  url?: string | null;
  oAuthSetting?: OAuthSettingDto;
  authLevel?: AuthLevel;
  authType?: AuthType;
  /** @maxLength 64 */
  apiKeyDisplayName?: string | null;
  /** @maxLength 32 */
  apiKeyHeaderName?: string | null;
  /** @maxLength 32 */
  apiKeyValuePrefix?: string | null;
  apiKeyValidationRequestInfo?: HttpRequestInfoDto;
  developerName?: string | null;
  /** @maxLength 64 */
  basicAuthUsernameDisplayName?: string | null;
  /** @maxLength 32 */
  basicAuthPasswordDisplayName?: string | null;
  basicAuthValidationRequestInfo?: HttpRequestInfoDto;
  oAuthValidationRequestInfo?: HttpRequestInfoDto;
  isRequired?: boolean;
  triggers?: TriggerDto[] | null;
  actions?: ActionDto[] | null;
  /** @maxLength 2048 */
  externalTenantIdAuthExtractor?: string | null;
  /** @maxLength 2048 */
  externalTenantIdTriggerExtractor?: string | null;
  /** @format uuid */
  externalTenantIdTriggerExtractorSampleTriggerId?: string | null;
  apiDocumentationUrl?: string | null;
  testPassed?: boolean;
  /** @format int32 */
  version?: number;
  /** @format uuid */
  rootPlatformId?: string | null;
  publishStatus?: PublishStatus;
  oAuthLoginUrl?: string | null;
  /** @format uuid */
  id?: string;
}

export interface PublicPlatformDto {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
  logoUrl?: string | null;
  productName?: string | null;
  vendorName?: string | null;
  url?: string | null;
  developerName?: string | null;
  authType?: AuthType;
  oAuthLoginUrl?: string | null;
}

export interface PublicTriggerDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  platformId?: string;
  platform?: PlatformDto;
  name?: string | null;
  codeName?: string | null;
  displayName?: string | null;
  noun?: string | null;
  summary?: string | null;
  helpText?: string | null;
  manualSampleData?: string | null;
  generatedSampleData?: string | null;
  triggerType?: TriggerType;
  dataProperties?: TriggerDataPropertyDto[] | null;
}

export enum PublishStatus {
  Private = "Private",
  SubmittedForReview = "SubmittedForReview",
  UnderReview = "UnderReview",
  RequiresChanges = "RequiresChanges",
  Approved = "Approved",
  Released = "Released",
  Archived = "Archived",
}

export enum SchemaType {
  Body = "Body",
  Parameters = "Parameters",
  Headers = "Headers",
}

export interface SelectNextStep {
  stepId?: string | null;
  condition?: string | null;
}

export interface Step {
  stepType?: string | null;
  id?: string | null;
  reference?: string | null;
  name?: string | null;
  cancelCondition?: string | null;
  errorBehavior?: WorkflowErrorHandling;
  /** @format date-span */
  retryInterval?: string | null;
  do?: Step[][] | null;
  compensateWith?: Step[] | null;
  saga?: boolean;
  nextStepId?: string | null;
  inputs?: Record<string, any>;
  outputs?: Record<string, string | null>;
  selectNextStep?: SelectNextStep[] | null;
}

export interface StepDefinitionDto {
  typeName?: string | null;
  displayName?: string | null;
  description?: string | null;
  hexColorCode?: string | null;
  icon?: string | null;
  isTrigger?: boolean;
  isAction?: boolean;
  inputs?: StepDefinitionPropertyDto[] | null;
  outputs?: StepDefinitionPropertyDto[] | null;
}

export interface StepDefinitionPropertyDto {
  name?: string | null;
  propertyName?: string | null;
  displayName?: string | null;
  propertyType?: string | null;
  description?: string | null;
}

export interface Tenant {
  name?: string | null;
  language?: string | null;
  languageCode?: string | null;
  languageSubCode?: string | null;
  accounts?: Account[] | null;
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string | null;
  /** @format uuid */
  createdByAccountId?: string | null;
  /** @format uuid */
  updatedByAccountId?: string | null;
  createdByEmail?: string | null;
  updatedByEmail?: string | null;
}

export interface TenantDto {
  name?: string | null;
  /** @format uuid */
  id?: string;
}

export interface TriggerDataPropertyDto {
  /** @format uuid */
  triggerId?: string;
  displayName?: string | null;
  description?: string | null;
  propertyName?: string | null;
  /** @format int32 */
  order?: number;
  propertyType?: JsonPropertyType;
  required?: boolean;
  enum?: string[] | null;
  /** @format double */
  maximum?: number | null;
  /** @format double */
  minimum?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  /** @format int32 */
  minLength?: number | null;
  format?: string | null;
  nullable?: boolean;
  /** @format int32 */
  maxItems?: number | null;
  /** @format int32 */
  maxProperties?: number | null;
  pattern?: string | null;
  deprecated?: boolean;
  schemaType?: SchemaType;
  defaultValue?: string | null;
  tenant?: Tenant;
  /** @format uuid */
  tenantId?: string | null;
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string | null;
  /** @format uuid */
  createdByAccountId?: string | null;
  /** @format uuid */
  updatedByAccountId?: string | null;
  createdByEmail?: string | null;
  updatedByEmail?: string | null;
}

export interface TriggerDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  platformId?: string;
  platform?: PlatformDto;
  name?: string | null;
  codeName?: string | null;
  displayName?: string | null;
  noun?: string | null;
  summary?: string | null;
  helpText?: string | null;
  manualSampleData?: string | null;
  generatedSampleData?: string | null;
  overrideSystemTenantIdExtractor?: boolean;
  tenantIdExtractor?: string | null;
  triggerType?: TriggerType;
  webhookType?: WebhookType;
  dynamicWebhookSubscribeHttpRequestInfo?: HttpRequestInfoDto;
  dynamicWebhookUnsubscribeHttpRequestInfo?: HttpRequestInfoDto;
  dataProperties?: TriggerDataPropertyDto[] | null;
  pollingHttpRequestInfo?: HttpRequestInfoDto;
  isTestPassed?: boolean;
  isTested?: boolean;
  webhookUrl?: string | null;
  hideFromAppDevelopers?: boolean;
}

export enum TriggerType {
  Webhook = "Webhook",
  Polling = "Polling",
}

export interface TriggersFromOpenApiDto {
  openApiUrl?: string | null;
  openApiText?: string | null;
  /** @format uuid */
  platformId?: string;
}

export interface TryActionDto {
  inputs?: Record<string, any>;
  data?: Record<string, any>;
}

export interface User {
  email?: string | null;
  enabled?: boolean;
  accounts?: Account[] | null;
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  deletedAt?: string | null;
  /** @format uuid */
  createdByAccountId?: string | null;
  /** @format uuid */
  updatedByAccountId?: string | null;
  createdByEmail?: string | null;
  updatedByEmail?: string | null;
}

export interface UserDto {
  email?: string | null;
  /** @format uuid */
  id?: string;
}

export enum WebhookType {
  Dynamic = "Dynamic",
  Static = "Static",
}

export interface WorkflowDto {
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  appId?: string;
  app?: AppDto;
  appName?: string | null;
  trigger?: TriggerDto;
  /** @format uuid */
  triggerId?: string | null;
  /** @format uuid */
  triggerPlatformId?: string | null;
  listenerUrl?: string | null;
  definition?: Definition;
  triggerSampleData?: string | null;
  triggerSuccess?: boolean;
  /** @format uuid */
  id?: string;
}

export enum WorkflowErrorHandling {
  Retry = "Retry",
  Suspend = "Suspend",
  Terminate = "Terminate",
  Compensate = "Compensate",
}

export interface WorkflowExceptionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  appId?: string;
  /** @format date-time */
  errorTime?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  workflowInstanceId?: string;
  /** @format uuid */
  workflowId?: string;
  workflowName?: string | null;
  appName?: string | null;
  stepName?: string | null;
  /** @format int32 */
  stepId?: number;
  message?: string | null;
  stackTrace?: string | null;
  stepExternalId?: string | null;
  environment?: EnvironmentType;
  workflowData?: Record<string, any>;
  workflowMeta?: Record<string, any>;
}

export interface WorkflowInstanceDto {
  id?: string | null;
  definitionId?: string | null;
  workflowId?: string | null;
  /** @format int32 */
  version?: number;
  description?: string | null;
  reference?: string | null;
  /** @format int64 */
  nextExecution?: number | null;
  data?: Record<string, any>;
  meta?: Record<string, any>;
  /** @format date-time */
  createTime?: string;
  /** @format date-time */
  completeTime?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title LiveSwitch.Exchange.API
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Authentication
   * @name CallbackList
   * @request GET:/callback
   * @secure
   */
  callbackList = (
    query?: {
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/callback`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });

  actions = {
    /**
     * No description
     *
     * @tags Action
     * @name ActionsList
     * @request GET:/api/actions
     * @secure
     */
    actionsList: (
      query?: {
        /** @format uuid */
        platformId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ActionDto[], any>({
        path: `/api/actions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsCreate
     * @request POST:/api/actions
     * @secure
     */
    actionsCreate: (data: ActionDto, params: RequestParams = {}) =>
      this.request<ActionDto, any>({
        path: `/api/actions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsAppList
     * @request GET:/api/actions/app
     * @secure
     */
    actionsAppList: (
      query?: {
        /** @format uuid */
        appId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ActionDto[], any>({
        path: `/api/actions/app`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsTryCreate
     * @request POST:/api/actions/{actionId}/try
     * @secure
     */
    actionsTryCreate: (actionId: string, data: TryActionDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/actions/${actionId}/try`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsSucceedCreate
     * @request POST:/api/actions/{actionId}/succeed
     * @secure
     */
    actionsSucceedCreate: (actionId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/actions/${actionId}/succeed`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsInputPropertiesCreate
     * @request POST:/api/actions/{actionId}/input-properties
     * @secure
     */
    actionsInputPropertiesCreate: (actionId: string, data: InputPropertyDto, params: RequestParams = {}) =>
      this.request<InputPropertyDto, any>({
        path: `/api/actions/${actionId}/input-properties`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsInputPropertiesUpdate
     * @request PUT:/api/actions/{actionId}/input-properties/{id}
     * @secure
     */
    actionsInputPropertiesUpdate: (actionId: string, id: string, data: InputPropertyDto, params: RequestParams = {}) =>
      this.request<InputPropertyDto, any>({
        path: `/api/actions/${actionId}/input-properties/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsInputPropertiesDelete
     * @request DELETE:/api/actions/{actionId}/input-properties/{id}
     * @secure
     */
    actionsInputPropertiesDelete: (actionId: string, id: string, params: RequestParams = {}) =>
      this.request<InputPropertyDto, any>({
        path: `/api/actions/${actionId}/input-properties/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsInputPropertiesOrderUpdate
     * @request PUT:/api/actions/{actionId}/input-properties/order
     * @secure
     */
    actionsInputPropertiesOrderUpdate: (actionId: string, data: OrderedRecordDto[], params: RequestParams = {}) =>
      this.request<OrderedRecordDto[], any>({
        path: `/api/actions/${actionId}/input-properties/order`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsOutputPropertiesCreate
     * @request POST:/api/actions/{actionId}/output-properties
     * @secure
     */
    actionsOutputPropertiesCreate: (actionId: string, data: OutputPropertyDto, params: RequestParams = {}) =>
      this.request<InputPropertyDto, any>({
        path: `/api/actions/${actionId}/output-properties`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsOutputPropertiesUpdate
     * @request PUT:/api/actions/{actionId}/output-properties/{id}
     * @secure
     */
    actionsOutputPropertiesUpdate: (
      actionId: string,
      id: string,
      data: OutputPropertyDto,
      params: RequestParams = {},
    ) =>
      this.request<OutputPropertyDto, any>({
        path: `/api/actions/${actionId}/output-properties/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsOutputPropertiesDelete
     * @request DELETE:/api/actions/{actionId}/output-properties/{id}
     * @secure
     */
    actionsOutputPropertiesDelete: (actionId: string, id: string, params: RequestParams = {}) =>
      this.request<OutputPropertyDto, any>({
        path: `/api/actions/${actionId}/output-properties/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsHttpRequestInfoUpdate
     * @request PUT:/api/actions/{actionId}/http-request-info/{id}
     * @secure
     */
    actionsHttpRequestInfoUpdate: (
      actionId: string,
      id: string,
      data: HttpRequestInfoDto,
      params: RequestParams = {},
    ) =>
      this.request<HttpRequestInfoDto, any>({
        path: `/api/actions/${actionId}/http-request-info/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsDetail
     * @request GET:/api/actions/{id}
     * @secure
     */
    actionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<ActionDto, any>({
        path: `/api/actions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsUpdate
     * @request PUT:/api/actions/{id}
     * @secure
     */
    actionsUpdate: (id: string, data: ActionDto, params: RequestParams = {}) =>
      this.request<ActionDto, any>({
        path: `/api/actions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsDelete
     * @request DELETE:/api/actions/{id}
     * @secure
     */
    actionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<ActionDto, any>({
        path: `/api/actions/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Action
     * @name ActionsOpenapiCreate
     * @request POST:/api/actions/openapi
     * @secure
     */
    actionsOpenapiCreate: (data: ActionsFromOpenApiDto, params: RequestParams = {}) =>
      this.request<ActionDto[], any>({
        path: `/api/actions/openapi`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  apps = {
    /**
     * No description
     *
     * @tags App
     * @name AppsList
     * @request GET:/api/apps
     * @secure
     */
    appsList: (params: RequestParams = {}) =>
      this.request<AppDto[], any>({
        path: `/api/apps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsCreate
     * @request POST:/api/apps
     * @secure
     */
    appsCreate: (data: AppDto, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsDetail
     * @request GET:/api/apps/{id}
     * @secure
     */
    appsDetail: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsUpdate
     * @request PUT:/api/apps/{id}
     * @secure
     */
    appsUpdate: (id: string, data: AppDto, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsDelete
     * @request DELETE:/api/apps/{id}
     * @secure
     */
    appsDelete: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name AppsStatusUpdate
     * @request PUT:/api/apps/{id}/status
     * @secure
     */
    appsStatusUpdate: (id: string, data: PublishStatus, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/apps/${id}/status`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  appExecutions = {
    /**
     * No description
     *
     * @tags AppExecution
     * @name AppExecutionsCreate
     * @request POST:/api/app-executions
     * @secure
     */
    appExecutionsCreate: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/app-executions`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppExecution
     * @name AppExecutionsList
     * @request GET:/api/app-executions
     * @secure
     */
    appExecutionsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/app-executions`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  appversions = {
    /**
     * No description
     *
     * @tags AppVersion
     * @name AppversionsCreate
     * @request POST:/api/appversions/{id}
     * @secure
     */
    appversionsCreate: (id: string, params: RequestParams = {}) =>
      this.request<AppDto, any>({
        path: `/api/appversions/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  authentication = {
    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLoginList
     * @request GET:/api/authentication/login
     * @secure
     */
    authenticationLoginList: (
      query?: {
        /** @default "/api/authentication/callback" */
        returnUrl?: string;
        /** @default false */
        raw?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/authentication/login`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationLogoutList
     * @request GET:/api/authentication/logout
     * @secure
     */
    authenticationLogoutList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/authentication/logout`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthenticationCallbackList
     * @request GET:/api/authentication/callback
     * @secure
     */
    authenticationCallbackList: (
      query?: {
        code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/authentication/callback`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  evaluation = {
    /**
     * No description
     *
     * @tags Evaluation
     * @name EvaluationJavascriptCreate
     * @request POST:/api/evaluation/javascript
     * @secure
     */
    evaluationJavascriptCreate: (data: CodeEvaluationDto, params: RequestParams = {}) =>
      this.request<CodeEvaluationResponseDto, any>({
        path: `/api/evaluation/javascript`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  me = {
    /**
     * No description
     *
     * @tags Me
     * @name GetMe
     * @request GET:/api/me
     * @secure
     */
    getMe: (params: RequestParams = {}) =>
      this.request<MeDto, any>({
        path: `/api/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Me
     * @name MeEmailList
     * @request GET:/api/me/email
     * @secure
     */
    meEmailList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/me/email`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  platforms = {
    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsList
     * @request GET:/api/platforms
     * @secure
     */
    platformsList: (
      query?: {
        include?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PlatformDto[], any>({
        path: `/api/platforms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsCreate
     * @request POST:/api/platforms
     * @secure
     */
    platformsCreate: (data: PlatformDto, params: RequestParams = {}) =>
      this.request<PlatformDto, any>({
        path: `/api/platforms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsDetail
     * @request GET:/api/platforms/{id}
     * @secure
     */
    platformsDetail: (id: string, params: RequestParams = {}) =>
      this.request<PlatformDto, any>({
        path: `/api/platforms/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsUpdate
     * @request PUT:/api/platforms/{id}
     * @secure
     */
    platformsUpdate: (id: string, data: PlatformDto, params: RequestParams = {}) =>
      this.request<PlatformDto, any>({
        path: `/api/platforms/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsDelete
     * @request DELETE:/api/platforms/{id}
     * @secure
     */
    platformsDelete: (id: string, params: RequestParams = {}) =>
      this.request<PlatformDto, any>({
        path: `/api/platforms/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsPublishCreate
     * @request POST:/api/platforms/publish/{platformId}
     * @secure
     */
    platformsPublishCreate: (platformId: string, params: RequestParams = {}) =>
      this.request<PlatformDto, any>({
        path: `/api/platforms/publish/${platformId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformsPublicList
     * @request GET:/api/platforms/public
     * @secure
     */
    platformsPublicList: (
      query?: {
        include?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PublicPlatformDto[], any>({
        path: `/api/platforms/public`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  platformAuthentications = {
    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsOauthCallbackDetail
     * @request GET:/api/platform-authentications/oauth/callback/{platformRandomId}
     * @secure
     */
    platformAuthenticationsOauthCallbackDetail: (
      platformRandomId: string,
      query?: {
        /** @default "" */
        code?: string;
        /** @default "" */
        state?: string;
        /** @default "" */
        error?: string;
        /** @default "" */
        error_description?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/platform-authentications/oauth/callback/${platformRandomId}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsApikeyCreate
     * @request POST:/api/platform-authentications/apikey/{platformId}
     * @secure
     */
    platformAuthenticationsApikeyCreate: (
      platformId: string,
      data: ApiKeyAuthDto,
      query?: {
        /** @format uuid */
        appId?: string;
        /** @default true */
        extractPlatformAccountId?: boolean;
        /** @default true */
        saveChanges?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlatformAuthDto, any>({
        path: `/api/platform-authentications/apikey/${platformId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsBasicCreate
     * @request POST:/api/platform-authentications/basic/{platformId}
     * @secure
     */
    platformAuthenticationsBasicCreate: (
      platformId: string,
      data: BasicAuthDto,
      query?: {
        /** @format uuid */
        appId?: string;
        /** @default true */
        extractPlatformAccountId?: boolean;
        /** @default true */
        saveChanges?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlatformAuthDto, any>({
        path: `/api/platform-authentications/basic/${platformId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsOauthCreate
     * @request POST:/api/platform-authentications/oauth/{platformId}
     * @secure
     */
    platformAuthenticationsOauthCreate: (
      platformId: string,
      query?: {
        /** @format uuid */
        appId?: string;
        code?: string;
        /** @default true */
        extractPlatformAccountId?: boolean;
        /** @default true */
        saveChanges?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlatformAuthDto, any>({
        path: `/api/platform-authentications/oauth/${platformId}`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsList
     * @request GET:/api/platform-authentications
     * @secure
     */
    platformAuthenticationsList: (
      query?: {
        /** @format uuid */
        platformId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PlatformAuthDto[], any>({
        path: `/api/platform-authentications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlatformAuthentication
     * @name PlatformAuthenticationsDelete
     * @request DELETE:/api/platform-authentications/{id}
     * @secure
     */
    platformAuthenticationsDelete: (id: string, params: RequestParams = {}) =>
      this.request<PlatformAuthDto, any>({
        path: `/api/platform-authentications/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  publicapps = {
    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsList
     * @request GET:/api/publicapps
     * @secure
     */
    publicappsList: (params: RequestParams = {}) =>
      this.request<AppDto[], any>({
        path: `/api/publicapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsConnectDetail
     * @request GET:/api/publicapps/connect/{appId}
     * @secure
     */
    publicappsConnectDetail: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/connect/${appId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsInstallCreate
     * @request POST:/api/publicapps/install/{appId}
     * @secure
     */
    publicappsInstallCreate: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/install/${appId}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicApp
     * @name PublicappsUninstallCreate
     * @request POST:/api/publicapps/uninstall/{appId}
     * @secure
     */
    publicappsUninstallCreate: (appId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/publicapps/uninstall/${appId}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  status = {
    /**
     * No description
     *
     * @tags Status
     * @name StatusList
     * @request GET:/api/status
     * @secure
     */
    statusList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/status`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Status
     * @name StatusExtendedList
     * @request GET:/api/status/extended
     * @secure
     */
    statusExtendedList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/status/extended`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  stepdefinitions = {
    /**
     * No description
     *
     * @tags StepDefinition
     * @name StepdefinitionsList
     * @request GET:/api/stepdefinitions
     * @secure
     */
    stepdefinitionsList: (data: string[], params: RequestParams = {}) =>
      this.request<StepDefinitionDto[], any>({
        path: `/api/stepdefinitions`,
        method: "GET",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  triggers = {
    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersList
     * @request GET:/api/triggers
     * @secure
     */
    triggersList: (
      query?: {
        /** @format uuid */
        platformId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TriggerDto[], any>({
        path: `/api/triggers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersCreate
     * @request POST:/api/triggers
     * @secure
     */
    triggersCreate: (data: TriggerDto, params: RequestParams = {}) =>
      this.request<TriggerDto, any>({
        path: `/api/triggers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersAppList
     * @request GET:/api/triggers/app
     * @secure
     */
    triggersAppList: (
      query?: {
        /** @format uuid */
        appId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TriggerDto[], any>({
        path: `/api/triggers/app`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersTryCreate
     * @request POST:/api/triggers/{triggerId}/try
     * @secure
     */
    triggersTryCreate: (triggerId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/triggers/${triggerId}/try`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersSucceedCreate
     * @request POST:/api/triggers/{triggerId}/succeed
     * @secure
     */
    triggersSucceedCreate: (triggerId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/triggers/${triggerId}/succeed`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersDetail
     * @request GET:/api/triggers/{id}
     * @secure
     */
    triggersDetail: (id: string, params: RequestParams = {}) =>
      this.request<TriggerDto, any>({
        path: `/api/triggers/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersUpdate
     * @request PUT:/api/triggers/{id}
     * @secure
     */
    triggersUpdate: (id: string, data: TriggerDto, params: RequestParams = {}) =>
      this.request<TriggerDto, any>({
        path: `/api/triggers/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersDelete
     * @request DELETE:/api/triggers/{id}
     * @secure
     */
    triggersDelete: (id: string, params: RequestParams = {}) =>
      this.request<TriggerDto, any>({
        path: `/api/triggers/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersOpenapiCreate
     * @request POST:/api/triggers/openapi
     * @secure
     */
    triggersOpenapiCreate: (data: TriggersFromOpenApiDto, params: RequestParams = {}) =>
      this.request<TriggerDto[], any>({
        path: `/api/triggers/openapi`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersDataPropertiesCreate
     * @request POST:/api/triggers/{triggerId}/data-properties
     * @secure
     */
    triggersDataPropertiesCreate: (triggerId: string, data: TriggerDataPropertyDto, params: RequestParams = {}) =>
      this.request<TriggerDataPropertyDto, any>({
        path: `/api/triggers/${triggerId}/data-properties`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersDataPropertiesUpdate
     * @request PUT:/api/triggers/{triggerId}/data-properties/{id}
     * @secure
     */
    triggersDataPropertiesUpdate: (
      triggerId: string,
      id: string,
      data: TriggerDataPropertyDto,
      params: RequestParams = {},
    ) =>
      this.request<TriggerDataPropertyDto, any>({
        path: `/api/triggers/${triggerId}/data-properties/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersDataPropertiesDelete
     * @request DELETE:/api/triggers/{triggerId}/data-properties/{id}
     * @secure
     */
    triggersDataPropertiesDelete: (triggerId: string, id: string, params: RequestParams = {}) =>
      this.request<TriggerDataPropertyDto, any>({
        path: `/api/triggers/${triggerId}/data-properties/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trigger
     * @name TriggersPublicList
     * @request GET:/api/triggers/public
     * @secure
     */
    triggersPublicList: (
      query?: {
        /** @format uuid */
        platformId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PublicTriggerDto[], any>({
        path: `/api/triggers/public`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  triggerInstances = {
    /**
     * No description
     *
     * @tags TriggerInstance
     * @name TriggerInstancesCreate
     * @request POST:/api/trigger-instances/{id}
     * @secure
     */
    triggerInstancesCreate: (
      id: string,
      data: Record<string, any>,
      query?: {
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/trigger-instances/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workflows = {
    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsList
     * @request GET:/api/workflows
     * @secure
     */
    workflowsList: (
      query?: {
        /** @format uuid */
        appId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkflowDto[], any>({
        path: `/api/workflows`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsCreate
     * @request POST:/api/workflows
     * @secure
     */
    workflowsCreate: (data: WorkflowDto, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsDetail
     * @request GET:/api/workflows/{id}
     * @secure
     */
    workflowsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsUpdate
     * @request PUT:/api/workflows/{id}
     * @secure
     */
    workflowsUpdate: (id: string, data: WorkflowDto, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowsDelete
     * @request DELETE:/api/workflows/{id}
     * @secure
     */
    workflowsDelete: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowDto, any>({
        path: `/api/workflows/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workflowdefinitions = {
    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsDetail
     * @request GET:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsDetail: (
      id: string,
      query?: {
        /** @format int32 */
        version?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsCreate
     * @request POST:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsCreate: (id: string, data: Definition, params: RequestParams = {}) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowDefinition
     * @name WorkflowdefinitionsUpdate
     * @request PUT:/api/workflowdefinitions/{id}
     * @secure
     */
    workflowdefinitionsUpdate: (
      id: string,
      data: Definition,
      query?: {
        workflowId?: string;
        /** @format int32 */
        version?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Definition, any>({
        path: `/api/workflowdefinitions/${id}`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  workflowExceptions = {
    /**
     * No description
     *
     * @tags WorkflowException
     * @name WorkflowExceptionsList
     * @request GET:/api/workflow-exceptions
     * @secure
     */
    workflowExceptionsList: (
      query?: {
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        page?: number;
        /**
         * @format int32
         * @min 1
         * @max 100
         */
        pageSize?: number;
        orderBys?: OrderBy[];
        /** @format uuid */
        workflowInstanceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkflowExceptionDto[], any>({
        path: `/api/workflow-exceptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkflowException
     * @name WorkflowExceptionsDetail
     * @request GET:/api/workflow-exceptions/{id}
     * @secure
     */
    workflowExceptionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WorkflowExceptionDto, any>({
        path: `/api/workflow-exceptions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
